export const cartTableField = [
  { text: '', align: 'center', value: 'picture' },
  { text: 'langkey.article', align: 'center', value: 'description', width: '300px' },
  { text: 'langkey.article_id', align: 'center', value: 'articleId' },
  { text: 'langkey.quantity', align: 'center', value: 'quantity' },
  { text: 'langkey.price', align: 'center', value: 'price' },
  { text: 'langkey.total', align: 'center', value: 'total' },
  { text: '', align: 'center', value: 'delete' },
];

export const cartTableFieldHasShipment = [
  { text: '', align: 'center', value: 'picture' },
  { text: 'langkey.article', align: 'center', value: 'description', width: '300px' },
  { text: 'langkey.article_id', align: 'center', value: 'articleId' },
  { text: 'langkey.shipment-type', align: 'center', value: 'shipmentType' },
  { text: 'langkey.quantity', align: 'center', value: 'quantity' },
  { text: 'langkey.price', align: 'center', value: 'price' },
  { text: 'langkey.total', align: 'center', value: 'total' },
  { text: '', align: 'center', value: 'delete' },
];