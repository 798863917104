<template>
  <section class="p-cart">
    <v-container>
      <v-card rounded="sm">
        <v-card-title class="card__title">
          <div class="left">
            <icon-cart-two class="cart__icon" />
            <h5 class="text">
              {{ $t('langkey.your-shopping-cart') }}
            </h5>
          </div>
          <v-btn
            class="clear-btn icon"
            outlined
            plain
            @click="onClickClearCart"
          >
            {{ $t('langkey.clear-cart') }} <icon-cart-clear />
          </v-btn>
        </v-card-title>

        <v-data-table
          :headers="cartTableField"
          :items="cartArticleItems"
          :loading="isLoading"
          :items-per-page="-1"
          class="primary-style cart-table"
          group-by="sourceName"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="(field, index) in cartTableField"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
          </template>
          <template v-slot:[`group`]="{ group, headers, items }">
            <tr class="supplier-header">
              <td
                v-if="group !== null"
                :colspan="isAllowAddressUser ? 2 : 8"
              >
                {{ group }}
              </td>
              <td
                v-if="isAllowAddressUser"
                colspan="6"
              >
                <div class="delivery-address">
                  <span class="label">{{ $t('langkey.extended_delivery_address') }}:</span>
                  <base-select-filter-dropdown
                    ref="selectDropdownDeliveryAddress"
                    v-model="selectedAddressOption[group]"
                    custom-content-class="cart-delivery-address-dropdown"
                    is-search-delivery-address
                    :is-loading="isLoadingDeliveryAddresses"
                    :option-items="getUserAddressOptions"
                    :placeholder="$t('langkey.select')"
                    is-radio
                    close-on-select
                    :disabled="isDirectDelivery(group, items) || !allowChangeAddressBySource[group]"
                    @input="onSelectAddress(group, items)"
                  />
                  <v-btn
                    v-if="!(isDirectDelivery(group, items) || !allowChangeAddressBySource[group])"
                    color="primary"
                    @click="createNewAddress"
                  >
                    {{ $t('langkey.adding_new_delivery_address') }}
                  </v-btn>
                </div>
              </td>
            </tr>
            <tr
              v-for="(groupItem, groupIndex) in items"
              :key="`cartTable${group}${groupIndex}`"
            >
              <template v-for="(field, fieldIndex) in headers">
                <td
                  v-if="groupItem.type !== 'ShipmentCartArticle'"
                  :key="`tableFiledGroup${group}${fieldIndex}`"
                  :class="[`text-${field.align}`]"
                >
                  <template v-if="field.value === 'picture'">
                    <img
                      v-if="groupItem[field.value] && groupItem.isVersionA"
                      :src="require(`@/assets/images/${groupItem[field.value]}`)"
                      class="cart-img"
                    >
                    <img
                      v-else-if="groupItem[field.value]"
                      :src="groupItem[field.value]"
                      class="cart-img"
                    >
                  </template>
                  <template v-else-if="field.value === 'description'">
                    <div
                      v-if="groupItem.type === 'TyreCartArticle'"
                      class="d-flex align-center"
                    >
                      <p class="mr-2">
                        <span v-if="groupItem[field.value].includes('langkey')">
                          {{ $t(groupItem[field.value]) }}
                        </span>
                        <span v-else>
                          {{ groupItem[field.value] }}
                        </span>
                      </p>
                      <button @click.prevent="onClickInfo($event, groupItem)">
                        <icon-info size="12" />
                      </button>
                    </div>
                    <p v-else>
                      <span v-if="groupItem[field.value].includes('langkey')">
                        {{ $t(groupItem[field.value]) }}
                      </span>
                      <span v-else>
                        {{ groupItem[field.value] }}
                      </span>
                    </p>
                  </template>
                  <template v-else-if="field.value === 'shipmentType'">
                    <icon-express-truck
                      v-if="groupItem.shipmentType === 'direct_delivery'"
                      style="position: relative; top: 2px;"
                    />
                  </template>
                  <template v-else-if="field.value === 'quantity'">
                    <div class="qty__wrapper">
                      <v-btn
                        :disabled="selectQuantity[groupItem.id] < 2"
                        icon
                        plain
                        @click="onSubtractQuantity(groupItem)"
                      >
                        <icon-subtract />
                      </v-btn>
                      <input
                        v-model.number="selectQuantity[groupItem.id]"
                        class="qty__input"
                        type="number"
                        @change="onQuantityChange($event, groupItem)"
                      >
                      <v-btn
                        :disabled="selectQuantity[groupItem.id] >= groupItem.stock"
                        icon
                        plain
                        @click="onAddQuantity(groupItem)"
                      >
                        <icon-add />
                      </v-btn>
                    </div>
                  </template>
                  <template v-else-if="field.value === 'price'">
                    <template v-if="getHasPriceChanges(groupItem)">
                      <span style="color:#000000;font-weight:bold;">{{ groupItem.price.price | price }}</span><br>
                      <span style="color:#8B8B8B;text-decoration:line-through;">{{ groupItem.oldPrice.price | price }}</span>
                    </template>
                    <template v-else>
                      {{ groupItem[field.value].price | price }}
                    </template>
                  </template>
                  <template v-else-if="field.value === 'total'">
                    {{ selectQuantity[groupItem.id] * groupItem.price.price | price }}
                  </template>
                  <template v-else-if="field.value === 'delete'">
                    <button @click="onClickDelete(groupItem)">
                      <icon-close
                        size="14"
                        color="#525252"
                      />
                    </button>
                  </template>
                  <template v-else>
                    {{ groupItem[field.value] }}
                  </template>
                </td>
              </template>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <v-row class="lower">
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            rounded="sm"
            class="cart__shopping-detail"
          >
            <v-card-title class="card__title">
              <h5 class="text">
                {{ $t('langkey.shipping-detail') }}
              </h5>
              <v-btn
                v-if="false"
                icon
                plain
                class="edit-btn"
                @click="onEditShippingDetails"
              >
                <icon-edit />
              </v-btn>
            </v-card-title>

            <div class="shopping-detail__form">
              <p class="label">
                {{ $t('langkey.shipping-internal-note') }}:
              </p>
              <v-text-field
                v-model="shippingInternalNote"
                :label="$t('langkey.shipping-internal-note-examples')"
                solo
                hide-details
              />
              <div class="d-flex justify-space-between align-center">
                <p class="label font-weight-bold">
                  {{ $t('langkey.coupon') }}:
                </p>
                <v-progress-circular
                  v-if="isLoadingCoupon"
                  indeterminate
                  color="accent"
                />
              </div>
              <form @submit.prevent="debounceAddCoupon">
                <v-text-field
                  v-model="coupon"
                  :class="['input-highlight', errorCoupon ? 'error' : null]"
                  :label="$t('langkey.coupon-message')"
                  solo
                  hide-details
                  :disabled="isLoadingCoupon"
                  @input="debounceAddCoupon"
                >
                  <template #prepend-inner>
                    <v-btn
                      v-if="coupon"
                      icon
                      plain
                      class="button__input-clear"
                      @click="clearCoupon"
                    >
                      <icon-close />
                    </v-btn>
                  </template>
                </v-text-field>
                <p
                  v-if="errorCoupon"
                  class="error-message"
                >
                  {{ errorCoupon }}
                </p>
              </form>
            </div>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            rounded="sm"
            class="cart__summary"
          >
            <v-card-title class="card__title">
              <h5 class="text">
                {{ $t('langkey.your-summary') }}
              </h5>
            </v-card-title>

            <div class="summary-list">
              <p class="label bold">
                {{ $t('langkey.summary-sum') }}:
              </p>
              <p class="value bold">
                {{ total | price }}
              </p>
              <p class="label bold">
                {{ $t('langkey.summary-shipping') }}:
              </p>
              <p class="value bold">
                {{ shipping | price }}
              </p>
              <p class="label bold">
                {{ $t('langkey.summary-grand-total') }}:
              </p>
              <p class="value bold">
                {{ excludingVatTotal | price }}
              </p>
              <!-- <p class="label">
                {{ $t('langkey.summary-total-exclude-vat') }}:
              </p>
              <p class="value">
                {{ excludingVatTotal | price }}
              </p> -->
              <p class="label">
                {{ $t('langkey.summary-total-include-vat') }}:
              </p>
              <p class="value">
                {{ excludingVatDiffence | price }}
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <div class="function">
        <v-btn
          :style="{
            pointerEvents: cartArticleItems.length === 0 ? 'none' : null
          }"
          color="accent"
          class="order__button icon"
          depressed
          :disabled="cartArticleItems.length === 0 || isLoadingCoupon"
          @click="onOrder"
        >
          {{ $t('langkey.order') }} <icon-order />
        </v-btn>
      </div>
    </v-container>

    <v-dialog
      key="deleteCartItemDialog"
      v-model="deleteDialog"
      persistent
      max-width="330"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('langkey.cart-delete-item-head') }}
        </v-card-title>
        <v-card-text>{{ $t('langkey.cart-confirm-delete') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="deleteDialog = false"
          >
            {{ $t('langkey.cart-cancel-delete') }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="deleteItem"
          >
            {{ $t('langkey.cart-delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      key="clearCartDialog"
      v-model="clearCartDialog"
      persistent
      max-width="330"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('langkey.cart-clear-head') }}
        </v-card-title>
        <v-card-text>{{ $t('langkey.cart-clear-confirm') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="clearCartDialog = false"
          >
            {{ $t('langkey.cart-clear-cancel') }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="clearCart"
          >
            {{ $t('langkey.cart-clear') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="createNewAddressDialog"
      max-width="652"
    >
      <v-card class="dialog create-new-address">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.add_delivery_address') }}
          </h5>
          <v-btn
            icon
            plain
            @click="createNewAddressDialog = false"
          >
            <icon-close size="18" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_address_name') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.name.value"
              :error-messages="[errorMessageInputAddressData.name]"
              :label="`${$t('langkey.enter-name')}`"
              solo
              @input="errorMessageInputAddressData.name = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_address_street') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.street.value"
              :error-messages="[errorMessageInputAddressData.street]"
              :label="`${$t('langkey.enter-street-name')}`"
              solo
              @input="errorMessageInputAddressData.street = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_address_number') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.streetNumber.value"
              :error-messages="[errorMessageInputAddressData.streetNumber]"
              :label="`${$t('langkey.enter-street-number')}`"
              solo
              @input="errorMessageInputAddressData.streetNumber = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_code') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.zip.value"
              :error-messages="[errorMessageInputAddressData.zip]"
              type="number"
              :label="`${$t('langkey.enter-zip')}`"
              solo
              @input="errorMessageInputAddressData.zip = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_city') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.city.value"
              :error-messages="[errorMessageInputAddressData.city]"
              :label="`${$t('langkey.enter-city-name')}`"
              solo
              @input="errorMessageInputAddressData.city = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_country') }} :
            </p>
            <v-select
              v-model="inputAddressData.countryCode.value"
              :error-messages="[errorMessageInputAddressData.countryCode]"
              :items="['DE']"
              item-text="label"
              item-value="value"
              solo
              @input="errorMessageInputAddressData.countryCode = ''"
            >
              <template #append>
                <icon-caret />
              </template>
            </v-select>
          </div>
          <div style="display: flex">
            <base-checkbox
              :value="inputAddressData.defaultValue.value"
              :label-text="$t('langkey.new_standard_delivery_address')"
              reverse
              @input="toggleDefaultValue"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            plain
            @click="createNewAddressDialog = false"
          >
            {{ $t('langkey.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="onSaveAddress"
          >
            {{ $t('langkey.save-address') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <tyre-search-detail-dialog
      ref="tyreSearchDetailDialogRef"
      :tyre-data="tyreDetailDialogItem"
      :tyre-image-item="tyreImageItem"
    />
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/cart.scss";
</style>

<script>
import IconCartTwo from '@/components/icons/IconCartTwo';
import IconCartClear from '@/components/icons/IconCartClear';
import IconSubtract from '@/components/icons/IconSubtract';
import IconAdd from '@/components/icons/IconAdd';
import IconEdit from '@/components/icons/IconEdit';
import IconOrder from '@/components/icons/IconOrder';
import IconExpressTruck from '@/components/icons/IconExpressTruck';
import IconCaret from '@/components/icons/IconCaret';
import IconInfo from '@/components/icons/IconInfo';
import TyreSearchDetailDialog from '@/components/TyreSearchDetailDialog';

import { cartTableField, cartTableFieldHasShipment } from './configs';
import { CartService, TyreService } from '@/services';

import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import debounce from 'lodash/debounce';

export default {
  components: {
    IconCartTwo,
    IconCartClear,
    IconSubtract,
    IconAdd,
    IconEdit,
    IconOrder,
    IconExpressTruck,
    IconCaret,
    IconInfo,
    TyreSearchDetailDialog,
  },
  data() {
    return {
      isLoading: false,
      cartArticleItems: [],
      selectQuantity: {},
      deleteDialog: false,
      clearCartDialog: false,
      shippingInternalNote: '',
      isLoadingDeliveryAddresses: false,
      selectedAddressOption: {},
      createNewAddressDialog: false,
      inputAddressData: {
        name: {
          required: true,
          value: '',
        },
        street: {
          required: true,
          value: '',
        },
        streetNumber: {
          required: true,
          value: '',
        },
        zip: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        countryCode: {
          required: false,
          value: 'DE',
        },
        defaultValue: {
          required: false,
          value: false,
        },
      },
      errorMessageInputAddressData: {
        name: '',
        street: '',
        streetNumber: '',
        zip: '',
        city: '',
        countryCode: '',
      },
      shippingArticlesVersionA: 4,
      selectedArticleId: null,
      selectedGroupItem: {},
      tyreDetailDialogItem: {},
      tyreImageItem: {},
      coupon: '',
      isLoadingCoupon: false,
      errorCoupon: '',
      allowChangeAddressBySource: {},
    };
  },
  computed: {
    ...mapState({
      userAddresses: (state) => state.user.userAddresses,
      isAllowAddressUser: (state) => state.user.isAllowAddressUser,
      sourceId: (state) => state.cart.sourceId,
      articlesVersionA: (state) => state.cart.articlesVersionA,
      cartSources: (state) => state.cart.cartSources,
      allowChangeAddressForSource: (state) => state.cart.allowChangeAddressForSource,
      selectedCoupon: (state) => state.cart.selectedCoupon,
    }),
    ...mapGetters({
      getCartItems: 'cart/getCartItems',
      getOnlyCartShipmentArticles: 'cart/getOnlyCartShipmentArticles',
      getAddressOptions: 'cart/getAddressOptions',
      getUserAddressOptions: 'user/getUserAddressOptions',
    }),
    cartTableField() {
      if (this.cartArticleItems.length === 0) {
        return cartTableField;
      }
      const hasShipment = this.cartArticleItems.some((item) => item.shipmentType === 'direct_delivery');
      if (hasShipment) {
        return cartTableFieldHasShipment;
      }
      return cartTableField;
    },

    total() {
      let result = 0;
      const nonShipmentArticles = this.cartArticleItems;
      if (nonShipmentArticles.length === 0) {
        return 0;
      }
      nonShipmentArticles.forEach((item) => {
        result += item.price.price * this.selectQuantity[item.id];
      });
      return result;
    },
    vatValue() {
      return 19;
    },
    totalWithVat() {
      return this.total + (this.total * this.vatValue / 100);
    },
    shipping() {
      const hasArticlesVersionA = this.cartArticleItems.some((item) => item.isVersionA);
      if (this.getOnlyCartShipmentArticles.length === 0) {
        if (hasArticlesVersionA) {
          return this.shippingArticlesVersionA;
        }
        return 0;
      }
      const getOnlyCartShipmentArticlesPrices = this.getOnlyCartShipmentArticles.map((item) => item.price.price);

      if (hasArticlesVersionA) {
        return getOnlyCartShipmentArticlesPrices.reduce((a, b) => a + b) + this.shippingArticlesVersionA;
      }
      return getOnlyCartShipmentArticlesPrices.reduce((a, b) => a + b);
    },
    shippingWithVat() {
      return this.shipping + (this.shipping * this.vatValue / 100);
    },
    excludingVatTotal() {
      return this.total + this.shipping;
    },
    excludingVatDiffence() {
      return this.excludingVatTotal * this.vatValue / 100;
    },
    grandTotal() {
      return this.totalWithVat + this.shippingWithVat;
    },
    cartArticleItemsRegularProcess() {
      return this.cartArticleItems.filter((item) => !item.isVersionA);
    },
    cartArticleItemsVersionA() {
      return this.cartArticleItems.filter((item) => item.isVersionA);
    },
  },
  async created() {
    this.isLoading = true;

    try {
      await this.$store.dispatch('cart/getCartItems');
      this.cartArticleItems = this.getCartItems;
    } finally {
      this.isLoading = false;
    }
    this.cartArticleItems.forEach((item) => {
      this.selectQuantity = {
        ...this.selectQuantity,
        [item.id]: item.quantity,
      };
    });
    this.$store.dispatch('cart/getAddress');

    const hasPriceChanges = this.cartArticleItems.some((item) => {
      return item.oldPrice.price !== 0 &&
        item.oldPrice.price !== item.price.price;
    });
    if (hasPriceChanges) {
      this.$store.dispatch('dialog/setShowErrorDialog', {
        status: true,
        title: this.$t('langkey.cart_prices_updated_header'),
        message: this.$t('langkey.cart_prices_updated'),
      });
    }

    this.coupon = this.selectedCoupon;
    this.getUserAddresses();
  },
  methods: {
    empty: _.isEmpty,
    onClickDelete(groupItem) {
      const artId = groupItem.id;
      if (groupItem.isVersionA) {
        this.selectedGroupItem = groupItem;
        this.deleteDialog = true;
        return;
      }
      this.selectedArticleId = artId;
      this.selectedGroupItem = {};
      this.deleteDialog = true;
    },
    deleteItem() {
      this.deleteDialog = false;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      if (!this.empty(this.selectedGroupItem)) {
        const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
        let updateArticlesVersionA;
        updateArticlesVersionA = articleVersionAData.reduce((prev,cur) => {
          return cur.id === this.selectedGroupItem.id ?
            prev :
            prev.concat(cur);
        }, []);
        this.$store.dispatch('cart/updateCartArticleVersionA', updateArticlesVersionA)
          .then(() => {
            this.cartArticleItems = this.getCartItems;
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      } else {
        this.$store.dispatch('cart/deleteCartPosition', this.selectedArticleId)
          .then(() => {
            this.cartArticleItems = this.getCartItems;
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      }
    },
    clearCart() {
      if (this.cartArticleItems.length === 0) {
        return;
      }
      this.clearCartDialog = false;
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const promise1 = this.$store.dispatch('cart/clearArticleVersionA');
      const promise2 = this.$store.dispatch('cart/clearCart');
      Promise.all([promise1, promise2])
        .then(() => {
          this.cartArticleItems = [];
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    onClickClearCart() {
      if (this.cartArticleItems.length === 0) {
        return;
      }
      this.clearCartDialog = true;
    },
    onQuantityChange(e, item) {
      const articleId = item.id;
      const previousQuantity = item.quantity;
      let quantity = this.selectQuantity[articleId];
      let isMax = false;

      if (item.type === 'TyreCartArticle' || item.isVersionA) {
        const max = item.stock;
        const value = Math.abs(parseInt(e.target.value, 10)) || 0;
        if (this.max !== null && value > max) {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.stock-limit'),
            message: this.$t(`langkey.stock-limit-at ${max}`),
          });
          quantity = max;
          isMax = true;
        } else if (value === 0) {
          quantity = 1;
        }
      }

      if (!quantity) {
        this.selectQuantity = {
          ...this.selectQuantity,
          [articleId]: previousQuantity,
        };
        return;
      }

      if (!item.isVersionA) {
        if (!isMax) {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: true,
          });
        }
        this.$store.dispatch('cart/editCartItemQuantityById', {
          id: articleId,
          quantity: quantity,
        })
          .then(() => {
            this.selectQuantity = {
              ...this.selectQuantity,
              [articleId]: quantity,
            };
          })
          .catch(() => {
            this.selectQuantity = {
              ...this.selectQuantity,
              [articleId]: previousQuantity,
            };
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.error-edit-quantity'),
              message: this.$t('langkey.please-try-again'),
            });
          })
          .finally(() => {
            if (!isMax) {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
            }
          });
      } else {
        this.selectQuantity = {
          ...this.selectQuantity,
          [articleId]: quantity,
        };

        const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
        let updateArticlesVersionA;
        updateArticlesVersionA = articleVersionAData.reduce((prev,cur) => {
          return cur.id === articleId ?
            prev.concat({
              ...cur,
              quantity: quantity,
            }) :
            prev.concat(cur);
        }, []);
        this.$store.dispatch('cart/updateCartArticleVersionA', updateArticlesVersionA);
      }
    },
    onSubtractQuantity(groupItem) {
      const articleId = groupItem.id;
      let newQty;
      if (this.selectQuantity[articleId] > 1) {
        newQty = this.selectQuantity[articleId] - 1;
      } else {
        newQty = 1;
      }

      if (!groupItem.isVersionA) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        this.$store.dispatch('cart/editCartItemQuantityById', {
          id: articleId,
          quantity: newQty,
        })
          .then(() => {
            this.selectQuantity = {
              ...this.selectQuantity,
              [articleId]: newQty,
            };
          })
          .catch(() => {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.error-edit-quantity'),
              message: this.$t('langkey.please-try-again'),
            });
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      } else {
        this.selectQuantity = {
          ...this.selectQuantity,
          [articleId]: newQty,
        };

        const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
        let updateArticlesVersionA;
        updateArticlesVersionA = articleVersionAData.reduce((prev,cur) => {
          return cur.id === articleId ?
            prev.concat({
              ...cur,
              quantity: newQty,
            }) :
            prev.concat(cur);
        }, []);
        this.$store.dispatch('cart/updateCartArticleVersionA', updateArticlesVersionA);
      }
    },
    onAddQuantity(groupItem) {
      const articleId = groupItem.id;
      let newQty = this.selectQuantity[articleId] + 1;

      if (!groupItem.isVersionA) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        this.$store.dispatch('cart/editCartItemQuantityById', {
          id: articleId,
          quantity: newQty,
        })
          .then(() => {
            this.selectQuantity = {
              ...this.selectQuantity,
              [articleId]: newQty,
            };
          })
          .catch(() => {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.error-edit-quantity'),
              message: this.$t('langkey.please-try-again'),
            });
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      } else {
        this.selectQuantity = {
          ...this.selectQuantity,
          [articleId]: newQty,
        };

        const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
        let updateArticlesVersionA;
        updateArticlesVersionA = articleVersionAData.reduce((prev,cur) => {
          return cur.id === articleId ?
            prev.concat({
              ...cur,
              quantity: newQty,
            }) :
            prev.concat(cur);
        }, []);
        this.$store.dispatch('cart/updateCartArticleVersionA', updateArticlesVersionA);
      }
    },
    onEditShippingDetails() {
      this.$router.push(
        this.$i18nRoute({
          name: 'cart-edit-shipping-address',
        }),
      );
    },
    async onOrder() {
      if (this.cartArticleItems.length !== 0) {
        let orderMails = [];
        let getCartCommentRequests = [];
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        const articleVersionAData = JSON.parse(sessionStorage.getItem('articlesVersionA')) || [];
        if (articleVersionAData.length !== 0) {
          articleVersionAData.forEach((item) => {
            orderMails.push(this.$store.dispatch('order/orderMail', {
              amount: item.quantity,
              articleName: this.$t(item.description),
              articleIdentification: item.articleId,
              deliveryAddressId: 0,
            }));
          });
        }
        if (this.cartArticleItemsRegularProcess.length !== 0) {
          if (this.shippingInternalNote === '') {
            const promise1 = orderMails;
            const promise2 = this.$store.dispatch('cart/orderCart');
            try {
              await Promise.all([promise1, promise2]);
              await this.$store.dispatch('cart/clearArticleVersionA');
              this.cartArticleItems = this.getCartItems;
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.order-success'),
                message: '',
              });
              this.clearCoupon();
            } catch (e) {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.error-order-cart'),
                message: e.errors.length === 0
                  ? this.$t('langkey.please-try-again')
                  : `<p>${this.$t('langkey.message')}:</p><p>${e.errors[0]}</p>`,
              });
            } finally {
              this.$store.dispatch('dialog/setLoadingDialog', {
                status: false,
              });
            }
            return;
          }
          getCartCommentRequests = this.cartArticleItemsRegularProcess.slice()
            .map((item) => {
              return new Promise((resolve, reject) => {
                CartService.editCartComment({
                  id: item.id,
                  comment: this.shippingInternalNote,
                })
                  .then((editRes) => {
                    if (editRes.status === 200 && editRes.data.status === false) {
                      reject();
                    } else if (editRes.status === 200) {
                      resolve();
                    } else {
                      reject();
                    }
                  });
              });
            });
        }
        try {
          await Promise.all([
            ...getCartCommentRequests,
            ...orderMails,
          ]);
          if (this.cartArticleItemsRegularProcess.length !== 0) {
            await this.$store.dispatch('cart/orderCart');
          }
          await this.$store.dispatch('cart/clearArticleVersionA');
          this.cartArticleItems = this.getCartItems;
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.order-success'),
            message: '',
          });
          this.clearCoupon();
        } catch (e) {
          if (!e) {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.error-order-cart'),
              message: this.$t('langkey.please-try-again'),
            });
          } else {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.error-order-cart'),
              message: e.errors.length === 0
                ? this.$t('langkey.please-try-again')
                : `<p>${this.$t('langkey.message')}:</p><p>${e.errors[0]}</p>`,
            });
          }
        } finally {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        }
      }
    },
    getHasPriceChanges(item) {
      return item.type !== 'ShipmentCartArticle' &&
        item.oldPrice.price !== 0 &&
        item.oldPrice.price !== item.price.price;
    },
    getUserAddresses() {
      this.$store.dispatch('user/checkAllowAddress')
        .then(() => {
          this.isLoadingDeliveryAddresses = true;
          this.$store.dispatch('user/getUserAdresses')
            .then(() => {
              this.initSelectedAddressOption();
              this.checkAllowChangeAddressForSource();
            })
            .finally(() => {
              this.isLoadingDeliveryAddresses = false;
            });
        });
    },
    initSelectedAddressOption() {
      const defaultAddress  = this.userAddresses.find((address) => address.default);
      this.cartSources.forEach((source) => {
        if (source.address) {
          // If differing delivery address is chosen
          const differingAddressId = source.address;
          const differingAddress = this.userAddresses.find((address) => address.id === differingAddressId);
          this.selectedAddressOption[source.name] = [`${differingAddress.name} ${differingAddress.street}, ${differingAddress.zip} ${differingAddress.city}, ${differingAddress.countryCode}`];
        } else {
          // default address
          this.selectedAddressOption[source.name] = [`${defaultAddress.name} ${defaultAddress.street}, ${defaultAddress.zip} ${defaultAddress.city}, ${defaultAddress.countryCode}`];
        }
      });
    },
    async checkAllowChangeAddressForSource() {
      const sources = [...new Set(this.cartArticleItems.map((item) => {
        return {
          sourceName: item.sourceName,
          sourceId: item.sourceId,
        };
      }))];
      for (const i in sources) {
        await this.$store.dispatch('cart/checkAllowChangeAddressForSource', {
          sourceId: sources[i].sourceId,
        });
        if (this.allowChangeAddressForSource) {
          this.allowChangeAddressBySource = {
            ...this.allowChangeAddressBySource,
            [sources[i].sourceName]: true,
          };
        } else {
          this.allowChangeAddressBySource = {
            ...this.allowChangeAddressBySource,
            [sources[i].sourceName]: false,
          };
        }
      }
    },
    isDirectDelivery(group, items) {
      const getShipmentType = items.find((item) => item.sourceName === group).shipmentType;
      return getShipmentType === 'direct_delivery';
    },
    onSelectAddress(group, items) {
      const sourceId = items[0].sourceId;
      if (this.selectedAddressOption[group].length === 1) {
        const newAddressId = this.userAddresses.find((address) => {
          return `${address.name} ${address.street}, ${address.zip} ${address.city}, ${address.countryCode}` === this.selectedAddressOption[group].join();
        }).id;
        this.$store.dispatch('cart/changeAddress', {
          sourceId: sourceId,
          addressId: newAddressId,
        })
          .then(() => {
            this.isLoading = true;
            this.$store.dispatch('cart/getCartItems')
              .then(() => {
                this.cartArticleItems = this.getCartItems;
              })
              .finally(() => {
                this.isLoading = false;
              });
          });
      }
      this.$forceUpdate();
    },
    createNewAddress() {
      this.inputAddressData = {
        name: {
          required: true,
          value: '',
        },
        street: {
          required: true,
          value: '',
        },
        streetNumber: {
          required: true,
          value: '',
        },
        zip: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        countryCode: {
          required: false,
          value: 'DE',
        },
        defaultValue: {
          required: false,
          value: false,
        },
      };
      this.createNewAddressDialog = true;
    },
    onSaveAddress() {
      this.validateInput();
      if (this.hasErrorInputAddress) {
        return;
      }
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      let payload = {};
      Object.keys(this.inputAddressData).forEach((key) => {
        payload = {
          ...payload,
          [key]: this.inputAddressData[key].value,
        };
      });
      this.$store.dispatch('user/addUserAddress', payload)
        .then(() => {
          this.createNewAddressDialog = false;
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    async onClickInfo(event, groupItem) {
      event.stopPropagation();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('tyre/getTyres', {
        eans: [groupItem.ean],
        isCart: true,
      })
        .then((res) => {
          this.tyreDetailDialogItem = res.data[0];
          setTimeout(() => {
            this.$refs.tyreSearchDetailDialogRef.show();
          }, 200);
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    debounceAddCoupon: debounce(function(e) {
      this.onAddCoupon();
    }, 2000),
    onAddCoupon() {
      if (this.coupon) {
        this.isLoadingCoupon = true;
        this.$store.dispatch('cart/addCouponToCart', {
          context: {
            code: this.coupon,
          },
        })
          .then(() => {
            this.$store.dispatch('cart/setCoupon', this.coupon);
            this.errorCoupon = '';
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.coupon-added-successfully'),
            });
          })
          .catch((err) => {
            if (err.response.status === 422) {
              this.errorCoupon = this.$t('langkey.coupon-invalid');
            } else if (err.response.status === 500) {
              this.errorCoupon = this.$t('langkey.coupn-error500');
            }
          })
          .finally(() => {
            this.isLoadingCoupon = false;
          });
      }
    },
    clearCoupon() {
      this.coupon = '';
      this.$store.dispatch('cart/setCoupon', this.coupon);
      this.errorCoupon = '';
    },
    validateInput () {
      Object.keys(this.inputAddressData).forEach((key) => {
        if (this.inputAddressData[key].required && !this.inputAddressData[key].value) {
          this.errorMessageInputAddressData = {
            ...this.errorMessageInputAddressData,
            [key]: this.$t('langkey.mandatory-entry-fields'),
          };
        }
      });
    },
    toggleDefaultValue() {
      this.inputAddressData.defaultValue.value = !this.inputAddressData.defaultValue.value;
    },
    isRegularDelivery(items) {
      return items.every((item) => item.shipmentType === 'normal' && item.shipmentType !== 'direct_delivery');
    },
  },
};
</script>
