<template>
  <section class="p-cart-edit-shipping-address">
    <v-container>
      <v-card rounded="sm">
        <v-card-title class="card__title">
          <div class="left">
            <icon-shipping-address class="shipping-address__icon" />
            <h5 class="text">
              {{ $t('langkey.edit-address-head') }}
            </h5>
          </div>
        </v-card-title>

        <v-card-text class="card__content">
          <p class="remark mb-3">
            * = {{ $t('langkey.mandatory-entry-fields') }}
          </p>

          <form
            class="content__wrapper"
            @submit.prevent="onSaveShippingAddress"
          >
            <v-row>
              <v-col md="12">
                <p class="label">
                  {{ $t('langkey.edit-address-company-name') }} <span v-if="inputAddress.name.required">*</span> :
                </p>
                <v-text-field
                  v-model="inputAddress.name.value"
                  :error-messages="[errorMessageInputAddress.name]"
                  :label="$t('langkey.edit-address-company-placeholder')"
                  hide-details="auto"
                  solo
                  @input="errorMessageInputAddress.name = ''"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="9">
                <p class="label">
                  {{ $t('langkey.edit-address-street') }} <span v-if="inputAddress.street.required">*</span> :
                </p>
                <v-text-field
                  v-model="inputAddress.street.value"
                  :error-messages="[errorMessageInputAddress.street]"
                  :label="$t('langkey.edit-address-street-placeholder')"
                  hide-details="auto"
                  solo
                  @input="errorMessageInputAddress.street = ''"
                />
              </v-col>
              <v-col md="3">
                <p class="label">
                  {{ $t('langkey.edit-address-street-number') }} <span v-if="inputAddress.streetNumber.required">*</span> :
                </p>
                <v-text-field
                  v-model="inputAddress.streetNumber.value"
                  :error-messages="[errorMessageInputAddress.streetNumber]"
                  :label="$t('langkey.edit-address-street-number-placeholder')"
                  hide-details="auto"
                  solo
                  @input="errorMessageInputAddress.streetNumber = ''"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4">
                <p class="label">
                  {{ $t('langkey.edit-address-country') }} <span>({{ $t('langkey.fixed') }})</span> <span v-if="inputAddress.country.required">*</span> :
                </p>
                <v-text-field
                  value="Germany"
                  disabled
                  solo
                  hide-details
                >
                  <template #append>
                    <icon-lock />
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="4">
                <p class="label">
                  {{ $t('langkey.edit-address-zipcode') }} <span v-if="inputAddress.zip.required">*</span> :
                </p>
                <v-text-field
                  v-model="inputAddress.zip.value"
                  :error-messages="[errorMessageInputAddress.zip]"
                  :label="$t('langkey.edit-address-zipcode-palceholder')"
                  hide-details="auto"
                  solo
                  @input="errorMessageInputAddress.zip = ''"
                />
              </v-col>
              <v-col md="4">
                <p class="label">
                  {{ $t('langkey.city') }} <span v-if="inputAddress.city.required">*</span> :
                </p>
                <v-text-field
                  v-model="inputAddress.city.value"
                  :error-messages="[errorMessageInputAddress.city]"
                  :label="$t('langkey.city')"
                  hide-details="auto"
                  solo
                  @input="errorMessageInputAddress.city = ''"
                />
              </v-col>
            </v-row>
            <button
              type="submit"
              style="display:none;"
            />
          </form>
        </v-card-text>
      </v-card>

      <div class="function">
        <v-btn
          depressed
          color="accent"
          class="save__button icon"
          @click="onSaveShippingAddress"
        >
          {{ $t('langkey.edit-address-save') }} <icon-check no-border />
        </v-btn>
      </div>
    </v-container>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/cart-edit-shipping-address.scss";
</style>

<script>
import IconShippingAddress from '@/components/icons/IconShippingAddress';
import IconLock from '@/components/icons/IconLock';
import IconCheck from '@/components/icons/IconCheck';

export default {
  components: {
    IconShippingAddress,
    IconLock,
    IconCheck,
  },
  data() {
    return {
      inputAddress: {
        name: {
          required: true,
          value: '',
        },
        street: {
          required: true,
          value: '',
        },
        streetNumber: {
          required: true,
          value: '',
        },
        zip: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        country: {
          required: true,
          value: 'Germany',
        },
        countryCode: {
          required: true,
          value: 'DE',
        },
      },
      errorMessageInputAddress: {
        name: '',
        street: '',
        streetNumber: '',
        zip: '',
        city: '',
      },
    };
  },
  computed: {
    hasError() {
      return Object.values(this.errorMessageInputAddress).some((val) => val !== '');
    },
  },
  methods: {
    validateInput() {
      Object.keys(this.inputAddress).forEach((key) => {
        if (this.inputAddress[key].required && this.inputAddress[key].value === '') {
          this.errorMessageInputAddress = {
            ...this.errorMessageInputAddress,
            [key]: this.$t('langkey.mandatory-entry-fields'),
          };
        }
      });
    },
    onSaveShippingAddress() {
      this.validateInput();
      if (this.hasError) {
        return;
      }

      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });

      this.$store.dispatch('cart/addAddress', {
        addressId: 1,
        name: this.inputAddress.name.value,
        street: this.inputAddress.street.value,
        streetNumber: this.inputAddress.streetNumber.value,
        zip: this.inputAddress.zip.value,
        country: this.inputAddress.country.value,
        countryCode: this.inputAddress.countryCode.value,
        city: this.inputAddress.city.value,
      })
        .then(() => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: 'Add shipping address',
            message: 'Add shipping address successful',
          });
          this.$router.replace(
            this.$i18nRoute({
              name: 'cart',
            }),
          );
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });

    },
  },
};
</script>