<template>
  <svg
    id="Component_466_1"
    data-name="Component 466 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_5952"
      data-name="Path 5952"
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <circle
      id="Ellipse_325"
      data-name="Ellipse 325"
      cx="9"
      cy="9"
      r="9"
      transform="translate(3 3)"
      class="svg-icon-fill"
      fill="white"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke-width="!noBorder ? 2 : 0"
    />
    <path
      id="Path_5953"
      data-name="Path 5953"
      d="M9,12l2,2,4-4"
      fill="none"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--theme-primary)',
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
};
</script>