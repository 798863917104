<template>
  <v-dialog
    v-model="showDialog"
    max-width="960"
  >
    <v-card class="dialog tyre-search-detail-dialog">
      <v-overlay
        v-if="isLoading"
        :z-index="20"
        :opacity="0.7"
        absolute
        color="#fff"
      >
        <v-progress-circular
          indeterminate
          color="accent"
        />
      </v-overlay>
      <button
        class="close-btn"
        @click="showDialog = false"
      >
        <icon-close size="12" />
      </button>
      <div class="left-side">
        <div class="tyre-image-container">
          <div class="image-bg-square" />
          <img
            v-if="tyreData.imageTemplate && !isMotorbike"
            :src="getImageByTemplate(tyreData.imageTemplate, 180, 200)"
            :alt="tyreData.manufacturer"
          >
          <img
            v-else-if="tyreImageItem.urlTemplate && isMotorbike"
            :src="getImageByTemplate(tyreImageItem.urlTemplate, 180, 200)"
            :alt="tyreData.manufacturer"
          >
        </div>
        <div class="text-center">
          <a
            v-if="tyreLabelData && tyreData.labelTemplate"
            :href="tyreLabelData.qrCodeContent"
            target="_blank"
            rel="nofollow"
          >
            <img
              :src="getImageByTemplate(tyreData.labelTemplate, 260, 340)"
              class="label-image"
            >
          </a>
          <img
            v-else-if="tyreData.labelTemplate"
            :src="getImageByTemplate(tyreData.labelTemplate, 260, 340)"
            class="label-image"
          >
        </div>
      </div>
      <div class="line" />
      <div class="right-side">
        <img
          v-if="tyreData.manufacturer"
          :src="`https://cdn.jfnet.de/tyrelogos/${tyreData.manufacturer.toLowerCase()}/300x100-cl-ffffff.png`"
          class="tyre-logo"
          :alt="tyreData.manufacturer"
        >
        <h5 class="right-side-title">
          {{ tyreData.manufacturer }} {{ tyreData.profile }}
          <span>
            <template v-if="tyreData.runFlat">
              {{ ' ' + tyreData.runFlat }}
            </template>
            <template v-if="tyreData.strengthened">
              {{ ' ' + tyreData.strengthened }}
            </template>
            <template v-if="tyreData.mudAndSnow === 'Y'">
              {{ ' M+S' }}
            </template>
            <template v-if="tyreData.threePeakMountainSnowFlake === 'Y'">
              {{ ' 3PMSF' }}
            </template>
            <template v-if="tyreData.oeIdentifier">
              {{ ' ' + tyreData.oeIdentifier }}
            </template>
          </span>
        </h5>
        <p class="right-side-subtitle">
          {{ tyreData.width }}/{{ tyreData.aspectRatio }} {{ tyreData.radial }} {{ tyreData.diameter }} {{ tyreData.loadIndex }} {{ tyreData.speedIndex }}
        </p>

        <div class="tyre-detail-card">
          <p class="label">
            {{ $t('langkey.article_id') }} (MAN)
          </p>
          <p class="value">
            {{ tyreData.manufacturerArticleId }}
          </p>
        </div>
        <div class="tyre-detail-card">
          <p class="label">
            {{ $t('langkey.ean') }}
          </p>
          <p class="value">
            {{ tyreData.ean }}
          </p>
        </div>

        <div
          v-if="forPage === 'truck'"
          class="tyre-detail-card"
        >
          <p class="label">
            {{ $t('langkey.axles') }}
          </p>
          <div
            v-if="tyreData.axleSteering && tyreData.axleDriving"
            class="value"
          >
            <p>{{ $t('langkey.drive_and_steering_axle') }}</p>
          </div>
          <div
            v-else
            class="value"
          >
            <p
              v-if="tyreData.axleDriving"
            >
              {{ $t('langkey.steer') }}
            </p>
            <p
              v-if="tyreData.axleSteering"
            >
              {{ $t('langkey.drive') }}
            </p>
            <p
              v-if="tyreData.axleTrailer"
            >
              {{ $t('langkey.trailer') }}
            </p>
          </div>
        </div>
        <div
          v-else-if="forPage === 'industrial' || forPage === 'agriculture' || forPage === 'earthMover'"
          class="tyre-detail-card more-detail"
        >
          <p class="label">
            {{ $t('langkey.tube_type') }}
          </p>
          <p class="value">
            {{ tyreData.tubeType || '-' }}
          </p>
          <p class="label">
            {{ $t('langkey.weight') }}
          </p>
          <p class="value">
            {{ tyreData.weight || '-' }}
          </p>
          <p class="label">
            {{ $t('langkey.plyRating') }}
          </p>
          <p class="value">
            {{ tyreData.plyRating || '-' }}
          </p>
          <p class="label">
            {{ $t('langkey.usage') }}
          </p>
          <p class="value">
            <span v-if="tyreData.usage">{{ $t(usageTranslationItems[tyreData.usage.toLowerCase()]) }}</span>
            <span v-else>-</span>
          </p>
        </div>
        <div
          v-else-if="!isMotorbike"
          class="tyre-detail-card"
        >
          <p class="label">
            {{ $t('langkey.oe-identifier') }}
          </p>
          <p class="value">
            {{ tyreData.oeIdentifier || '-' }}
          </p>
        </div>


        <hr>

        <div
          v-if="tyreDescData"
          class="tyre-description mt-4"
        >
          <p
            v-if="tyreDescData.description"
            class="description-wrapper"
            style="white-space: pre-line;"
            v-html="tyreDescData.description"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
@import '@/styles/components/tyre-search-detail-dialog.scss';
</style>

<script>
import { TyreService } from '@/services';
import { LANGUAGES } from '@/constants/trans';
import { usageTranslationItems } from '@/constants/usageTranslation';

export default {
  props: {
    tyreData: {
      type: Object,
      default: () => {},
    },
    forPage: {
      type: String,
      default: '',
    },
    isMotorbike: {
      type: Boolean,
      default: false,
    },
    tyreImageItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showDialog: false,
      isLoadingImage: false,
      isLoadingLabel: false,
      isLoadingDesc: false,
      tyreImageData: null,
      tyreLabelData: null,
      tyreDescData: null,
      usageTranslationItems: usageTranslationItems,
    };
  },
  computed: {
    isLoading() {
      return this.isLoadingImage || this.isLoadingLabel || this.isLoadingDesc;
    },
  },
  watch: {
    async showDialog(val) {
      if (val) {
        this.isLoadingLabel = true;
        try {
          const labelResponse = await TyreService.getTyreLabel({
            eans: this.tyreData.ean,
          });
          if (labelResponse.status === 200) {
            this.tyreLabelData = labelResponse.data[0];
          }
        } finally {
          this.isLoadingLabel = false;
        }

        this.isLoadingDesc = true;
        const foundedLang = LANGUAGES.find((item) => item.iso === this.$route.params.lang);
        const locale = foundedLang ? foundedLang.locale : 'de_DE';
        try {
          const descResponse = await TyreService.getTyreDescriptionLocale({
            tyreTag: this.tyreData.tyreTag,
            locales: locale,
          });
          if (descResponse.status === 200) {
            this.tyreDescData = descResponse.data;
          }
        } finally {
          this.isLoadingDesc = false;
        }
      }
    },
  },
  methods: {
    show() {
      this.showDialog = true;
    },
    getImageByTemplate(image, width, height) {
      let result = image;
      result = result.replace('{width}', width);
      result = result.replace('{height}', height);
      result = result.replace('{orientation}', 'cc');
      if (image.includes('{bgColor}')) {
        result = result.replace('{bgColor}', 'xxxxxx');
      } else if (image.includes('{backgroundColor}')) {
        result = result.replace('{backgroundColor}', 'xxxxxx');
      }
      if (image.includes('{extension}')) {
        result = result.replace('{extension}', 'png');
      } else if (image.includes('{fileExtension}')) {
        result = result.replace('{fileExtension}', 'png');
      }
      return result;
    },
  },
};
</script>
